<template>
  <div>
    <van-nav-bar
      title="奖励规则"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />

    <div class="my_tips">
      1.每推荐一个用户购买成功，奖励10元。一个自然月内，推荐达到一定数量，可获得额外奖励，推荐越多，奖励越多！<br/>
      2.奖励金额会根据市场变化实时调整，以本页最新公布规则为准<br/>
      3.金牌合伙人不受月推荐数量限制，额外奖励全部按最高奖励金额计算<br/>
      4.一张身份证只能注册认证一个账号，一个人注册多个账号属于违规行为，违规收入将取消<br/>
      最新额外奖励规则如下：
      <table class="my_tb">
        <tr><th colspan="2">额外奖励规则（2021年6月1日开始执行）</th></tr>
        <tr><td>每月推荐数量</td><td>奖励规则</td></tr>
        <tr><td>1～9</td><td>没有额外奖励</td></tr>
        <tr><td>10～49</td><td>额外奖励金额=推荐数量*10元</td></tr>
        <tr><td>50～99</td><td>额外奖励金额=推荐数量*15元</td></tr>
        <tr><td>100～199</td><td>额外奖励金额=推荐数量*20元</td></tr>
        <tr><td>200～299</td><td>额外奖励金额=推荐数量*25元</td></tr>
        <tr><td>300及以上</td><td>额外奖励金额=推荐数量*30元</td></tr>
      </table>
    例如： <br/><b>普通合伙人：</b>
    <br/>推荐50个用户购买成功，月收入为：10*50+15*50 = 1250元
    <br/>推荐200个用户购买成功，月收入为：10*200+25*200 = 7000元<br/>
    <b>金牌合伙人：</b>
      <br/>推荐50个用户购买成功，月收入为：10*50+30*50 = 2000元
      <br/>推荐200个用户购买成功，月收入为：10*200+30*200 = 8000元<br/>
    </div>
    
    <CopyRight></CopyRight> 
  </div>
</template>

<script>
import CopyRight from "@/components/CopyRight.vue";
export default {
  components: {
    CopyRight,
  },
  methods: {
    onClickLeft() {
      this.$router.back();
    },
  },
};
</script>
